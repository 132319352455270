import React from 'react';
import { PageHero, Link, List, PageWithSubNav, Paragraph, Section, SectionSubhead } from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Headings & Lists" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Writing Guidelines" tierThree="Headings & Lists" />

      <Section>
        <Paragraph>
          Organizing ideas as short bullets under a clear headline is a great way to make better sense of{' '}
          <Link href="/words/content-elements/labels-non-label-text">non-label text</Link>.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Things to Keep in Mind</SectionSubhead>
        <List type="unordered">
          <li>In a perfect world, every list will have at least three bullets.</li>
          <li>Be sure to style all three bullets the same (e.g., either all or none are complete sentences).</li>
          <li>
            <Link href="https://www.evergreen.edu/sites/default/files/writingcenter/handouts/grammar/parallel.pdf">
              Parallel structure
            </Link>{' '}
            is required.
          </li>
          <li>If the list is a set of steps, use numbers instead of bullets.</li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
